import React, { useEffect, useState } from "react";
import linkedin from "../../static/logo/In-Blue-128@2x.png";
import github from "../../static/logo/github-mark.png";
import email from "../../static/logo/mailLogo.png";
import val from "../../static/images/val.jpg";
import alvoc from "../../static/images/sawyer.jpg";

// Main Introduction Component
export default function Introduction({ theme }) {
	const [backgroundImage, setBackgroundImage] = useState("");
	const [transformStyles, setTransformStyles] = useState({
		linkedin: { transform: "rotateX(0deg) rotateY(0deg)" },
		github: { transform: "rotateX(0deg) rotateY(0deg)" },
		email: { transform: "rotateX(0deg) rotateY(0deg)" },
	});

	const preloadImages = (urls) => {
		const promises = urls.map(
			(url) =>
				new Promise((resolve, reject) => {
					const img = new Image();
					img.src = url;
					img.onload = resolve;
					img.onerror = reject;
				})
		);
		return Promise.all(promises);
	};

	useEffect(() => {
		const images = [alvoc, val];

		preloadImages(images)
			.then(() => {
				setBackgroundImage(theme === "dark" ? `url(${val})` : `url(${alvoc})`);
			})
			.catch((error) => console.error("Error preloading images:", error));
	}, [theme]);

	const handleMouseMove = (e, type) => {
		const rect = e.target.getBoundingClientRect();
		const x = (e.clientX - rect.left) / rect.width - 0.5;
		const y = (e.clientY - rect.top) / rect.height - 0.5;

		let rotateX = y * 50;
		let rotateY = -x * 50;

		rotateX = Math.round(rotateX * 80) / 100;
		rotateY = Math.round(rotateY * 80) / 100;

		setTransformStyles((prevState) => ({
			...prevState,
			[type]: {
				transform: `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`,
			},
		}));
	};

	const handleMouseLeave = (type) => {
		setTransformStyles((prevState) => ({
			...prevState,
			[type]: { transform: "rotateX(0deg) rotateY(0deg)" },
		}));
	};

	return (
		<div
			className="h-screen introBackground rounding-t dark:border-t dark:border-l dark:border-r dark:border-teal-400 border-t border-l border-r border-slate-200  overflow-hidden relative z-30 background-transition"
			style={{
				backgroundImage: `${backgroundImage}`,
				transition: "background-image 1s ease-in-out",
			}}>
			<div className="flex pt-36 h-full relative z-20 backdrop-blur-3xl">
				<div className="flex flex-col w-full">
					<div className="flex flex-col ">
						<p className="text-center -ml-14 font-bebas text-5xl text-zinc-800 dark:text-zinc-900 background-transition ">
							Howdy, i'm
						</p>
						<div className="flex flex-row justify-center">
							<p className="text-center font-bebas text-5xl text-white -ml-4 background-transition ">
								Evan
							</p>
							<div className="flex flex-col justify-center items-center -mt-1 ml-4 text-left  font-kdam text-xs dark:text-zinc-400  text-zinc-300 background-transition ">
								<p className="">Software Developer</p>
								<p className="-ml-12">Atlanta, GA</p>
							</div>
						</div>
					</div>
					<div className="flex flex-row items-center justify-center mt-10 -mb-16">
						<a
							className="w-fit mx-6 introLinksLevitate"
							href="https://www.linkedin.com/in/evanamedi/"
							target="_blank"
							rel="noreferrer">
							<img
								src={linkedin}
								className="h-10 introLinks bg-white"
								alt="linkedin logo"
								style={transformStyles.linkedin}
								onMouseMove={(e) => handleMouseMove(e, "linkedin")}
								onMouseLeave={() => handleMouseLeave("linkedin")}
							/>
						</a>
						<a
							className="w-fit mx-6"
							href="https://github.com/evanamedi"
							target="_blank"
							rel="noreferrer">
							<img
								src={github}
								className="h-10 introLinks bg-white"
								alt="Github logo"
								style={transformStyles.github}
								onMouseMove={(e) => handleMouseMove(e, "github")}
								onMouseLeave={() => handleMouseLeave("github")}
							/>
						</a>
						<a
							className="w-fit mx-6"
							href="mailto:contact@evanamedi.com?subject=Development Work&body=Yes hello, I would like to work with you.">
							<img
								src={email}
								className="h-10 introLinks bg-white"
								alt="email icon"
								style={transformStyles.email}
								onMouseMove={(e) => handleMouseMove(e, "email")}
								onMouseLeave={() => handleMouseLeave("email")}
							/>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
